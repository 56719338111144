import * as React from "react";
import "./index.scss";
import Logo from "../images/quick.svg";
// markup
const IndexPage = () => {
    return (
        <main className="main-page">
            <img src={Logo}></img>
            <div className="footer-page">
                <a href="/privacy">Pólitica de Privacidad</a>
                <a href="/terms">Términos y Condiciones</a>
            </div>
        </main>
    );
};

export default IndexPage;
